<template>
  <RealisasiVariableKpiDivisiForm mode="Ubah" module="Realisasi Variable KPI"> </RealisasiVariableKpiDivisiForm>
</template>

<script>
import RealisasiVariableKpiDivisiForm from './form';

const RealisasiVariableKpiDivisiUpdate = {
  name: 'RealisasiVariableKpiDivisiUpdate',
  components: { RealisasiVariableKpiDivisiForm },
};

export default RealisasiVariableKpiDivisiUpdate;
</script>
